<template>
    <div class="text-content" v-html="content"></div>
</template>

<script setup>
import { strings } from '../strings.js';
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import api from '../api.js';

const content = ref(strings.Loading);

const router = useRouter();
const route = useRoute();

onMounted(() => {
  const id = route.params.id;
  router.isReady().then(() => {
    api.fetchContent(id, (response) => { content.value = response; });
  })

  router.afterEach((to, from) => {
    if (to.path !== from.path) {
      api.fetchContent(to.params.id, (response) => { content.value = response; });
    }
  });
});
</script>
