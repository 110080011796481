const strings = {
  Title: "DMV Realty Pro",
  Loading: "...",
  Home: "Home",
  Header: "Header",
  Footer: "Footer",
  Content: "Content",
  HomeFinder: "Home Finder",
  About: "About Us",
  Contact: "Contact Us",
  Admin: "Admin",
  LoginTitle: "Login",
  Logout: "Logout",
  UsernameLabel: "Username",
  PasswordLabel: "Password",
  SubmitButton: "Submit",
  ResetButton: "Clear form",
  CancelButton: "Cancel",
  NameLabel: "Name",
  PhoneLabel: "Phone",
  EmailLabel: "Email",
  MessageLabel: "Message",
  NamePlaceholder: "Your name",
  PhonePlaceholder: "Your phone",
  EmailPlaceholder: "Your email",
  MessagePlaceholder: "Optional message",
  AddressLabel: "Address",
  AddressPlaceholder: "Enter address",
  PropertySizeLabel: "Size (sqft)",
  PropertySizePlaceholder: "sqft",
  BedroomsLabel: "Bedrooms",
  BedroomsPlaceholder: "number",
  BathroomsLabel: "Bathrooms",
  BathroomsPlaceholder: "number",
  ContactFormTitle: "Get In Touch",
  ContactFormText: "If there are any questions that you have about buying or selling a home or you are just looking for some real estate advice, please contact us anytime or fill in the form below.",
  ContactSubmitted: "Thank you for contacting us. We will get back to you soon.",
  ContactError: "There was an error submitting your contact. Please try again.",
  ApproximateLocation: "Approximate Location",
  OpenHouse: "Open House",
  AgentRequest: "If you're working with an agent, please provide both the agent's name along with the name of their company.",
  AgentNameLabel: "Agent's name",
  AgentNamePlaceholder: "Agent's name",
  AgentCompanyLabel: "Company name",
  AgentCompanyPlaceholder: "Company name",
  OpenHouseMessageLabel: "How did you hear about this open house?",
  OpenHouseConfirmation: "Thank you for your interest! We'll share the property details and a Real Estate Buyer Guide with you within 48 hours. Please feel free to reach out if you have any questions in the meantime.",
}

export { strings };