import { createApp } from 'vue'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import App from './App.vue'
import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import '../scss/custom.scss';
import api from './api.js';
import router from './router.js';

// Navigation guard to check for authentication
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!api.isAuthenticated()) {
            next({ name: 'Login'}); // Redirect to login if not authenticated
        } else {
            next(); // Proceed to the route
        }
    } else {
        next(); // Always call next()!
    }
});

const app = createApp(App)
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(router)
app.mount('#app')
