<template>
    <div class="content">
        <div class="px200 content-list">
            <div class="content-item" v-for="item in items" :key="item.id" @click="selectItem(item)" :class="{ selected: activeItem === item }">
                <a :href="`/api/content/${item.id}`" @click.prevent>{{ item.title }}<span class="right-align">[{{ item.id }}]</span> </a>
            </div>
            <div class="content-item mt-3 border-top" @click="addItem" :class="{ selected: activeItem === null && contentItem !== null }">New Item</div>
        </div>
        <div class="full-minus-px200" v-if="contentItem">
            <div class="format-options">
                Format:
                <input type="radio" id="markdown" name="format" value="markdown" v-model="contentItem.format" :disabled="contentItem.format == 'image' && contentItem.content">
                <label for="markdown">Markdown</label>
                <input type="radio" id="html" name="format" value="html" v-model="contentItem.format" :disabled="contentItem.format == 'image' && contentItem.content">
                <label for="html">HTML</label>
                <input type="radio" id="image" name="format" value="image" v-model="contentItem.format" :disabled="contentItem.format == 'image' && contentItem.content">
                <label for="image">Image</label>
                <span style="margin-left: 20px;">Title: <input type="text" v-model="contentItem.title" required :readonly="contentItem.required"></span>
                <button class="save-btn btn-primary" @click="saveContent" :disabled="!contentItem.title || !contentItem.format || contentItem.format != 'html' && !contentItem.content">Save</button>
                <button class="save-btn btn-secondary" @click="deleteItem" v-if="contentItem.id && !contentItem.required">Delete</button>
            </div>
            <div class="content-container" v-if="contentItem.format == 'markdown'">
                <div class="content-box">
                    <div class="content-header">Editor</div>
                    <textarea v-model="contentItem.content" @input="updatePreview"></textarea>
                </div>
                <div class="content-box">
                    <div class="content-header">Preview</div>
                    <div class="content-preview" v-html="previewContent"></div>
                </div>
            </div>
            <div class="content-container" v-if="contentItem.format == 'html'">
                <div class="content-box quill">
                    <QuillEditor ref="quillEditor" theme="snow" :content="contentItem.content" contentType="html" />
                </div>
            </div>
            <div class="content-container" v-if="contentItem.format == 'image'">
                <div class="content-box">
                    <div class="content-image-container">
                        <div class="content-image-placeholder" :style="{ backgroundImage: contentItem.content ? 'url(' + contentItem.content + ')' : 'none' }">
                            Drop image here or 
                            <input id="image-upload" type="file" @change="handleImageUpload" style="display: none;" ref="fileInput">
                            <button class="btn-primary" @click="triggerFileInput">Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import api from '../../api.js';
import { onMounted, ref } from 'vue';
import { marked } from 'marked';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const items = ref([]);
const activeItem = ref(null);
const contentItem = ref(null);
const previewContent = ref('');
const quillEditor = ref(null);

onMounted(() => {
    api.listContent((data) => {
        items.value = data;
    });
});

marked.use({
    breaks: true,
    gfm: true,
    silent: true,
})

const updatePreview = () => {
    if (contentItem.value.format == 'markdown' && contentItem.value.content) {
        previewContent.value = marked(contentItem.value.content);
    } else {
        previewContent.value = '';
    }
}

const getContentDetails = (id) => {
    api.getContentDetails(id, (data) => {
        contentItem.value = data;
        updatePreview();
    });
}

const selectItem = (item) => {
    activeItem.value = item;
    getContentDetails(item.id);
}

const saveContent = () => {
    if (contentItem.value.format == 'html') {
        contentItem.value.content = quillEditor.value.getHTML();
    }
    api.saveContent(contentItem.value, () => {
        let id = contentItem.value.id;
        api.listContent((data) => {
            items.value = data;
            if (!id) {
                window.items = items.value;
                id = items.value.map(item => item.id).sort((a, b) => a - b).pop();
            }
            activeItem.value = items.value.find(item => item.id == id);
            if (!contentItem.value.id) {
                getContentDetails(id);
            }
        });
    }, (error) => {
        alert(error);
    });
}

const addItem = () => {
    contentItem.value = { id: null, title: '', format: null, content: null };
    activeItem.value = null;
}

const deleteItem = () => {
    if (contentItem.value.id) {
        if (confirm('Are you sure you want to delete this item?')) {
            api.deleteContent(contentItem.value.id, () => {
                contentItem.value = null;
                api.listContent((data) => {
                    items.value = data;
                });
            });
        }
    }
}

const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            contentItem.value.content = e.target.result;
            if (!contentItem.value.title) {
                contentItem.value.title = file.name;
            }
        };
        reader.readAsDataURL(file);
    }
};

const triggerFileInput = () => {
    const fileInput = document.getElementById('image-upload');
    fileInput.click();
};
</script>

<style scoped>
.content {
    height: 100%;
    display: flex;
}

.content-list {
    text-align: left;
    border-right: 1px solid #ccc;
}

.content-item {
    padding: 3px;
    border-bottom: 1px solid #ccc;
    background-color: #f4f4f4;
    cursor: pointer;
}

.content-item a {
    text-decoration: none;
    color: inherit;
}

.content-item.selected {
    background-color: #ccc;
    border-bottom: 2px solid #007bff;
}

.format-options {
    text-align: left;
    padding: 3px;
    padding-left: 10px;
    height: 20px;
}

.format-options input[type="radio"] {
    margin-right: 4px;
    margin-left: 12px;
    margin-bottom: 4px;
}

.content-container {
    padding: 10px;
    height: calc(100% - 26px);
    display: flex;
    margin-top: 6px;
}

.content-header {
    height: 30px;
    padding: 3px;
    padding-left: 10px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}

.content-box {
    height: 100%;
    width: 100%;
    float: left;
    border: 1px solid #ccc;
}

.content-box textarea {
    height: calc(100% - 36px);
    width: 100%;
    border: none;
    padding: 4px;
    font-family: monospace;
    font-size: 0.9rem;
    outline: none;
    resize: none;
}

.content-preview {
    padding: 4px;
    height: 100%;
    width: 100%;
    float: left;
    text-align: left;
    height: calc(100% - 36px);
}

.content-box.quill {
    height: calc(100% - 44px);
    border: none;
}

.save-btn {
    margin-left: 10px;
    height: 30px;
}

.content-image-container {
    height: 100%;
    width: 100%;
    padding: 20px;
}

.content-image-placeholder {
    height: 100%;
    width: 100%;
    text-align: center;
    border: 1px dashed #ccc;
    border-radius: 4px;
    padding-top: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.right-align {
    float: right;
    color: #aaa;
}
</style>