import router from "./router";

class Api {
  isAuthenticated() {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = atob(token.split(".")[1]);
        return JSON.parse(decodedToken);
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  logout() {
    localStorage.removeItem("token");
    router.push("/login");
  }

  parseResponse(response, callback) {
    if (response.headers.get('Content-Type') &&
      response.headers.get('Content-Type').startsWith("application/json")) {
      response.json().then((data) => {
        if (data.message) {
          callback(data.message);
        } else {
          callback(data);
        }
      });
    } else {
      response.text().then((data) => {
        callback(data);
      });
    }
  }

  makeRequest(url, body, callback, errorCallback) {
    const headers = {};
    let token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if (body) {
      headers["Content-Type"] = "application/json";
    }
    fetch(url, {
      method: body ? "POST" : "GET",
      headers,
      body: body ? JSON.stringify(body) : undefined,
    }).then((response) => {
      if (response.status === 200) {
        if (response.headers.get('Content-Type') &&
            response.headers.get('Content-Type').startsWith("application/json")) {
          if (callback) {
            response.json().then((data) => {
              callback(data);
            });
          }
        } else {
          if (callback) {
            response.text().then((data) => {
              callback(data);
            });
          }
        }
      } else {
        if (response.status === 401) {
          localStorage.removeItem("token");
          if (router.currentRoute.value.path !== "/login") {
            router.push("/login");
          } else {
            this.parseResponse(response, errorCallback);
          }
        } else if (errorCallback) {
          this.parseResponse(response, errorCallback);
        }
      }
    });
  }

  getSettings(callback) {
    this.makeRequest("/api/settings", null, callback);
  }

  fetchContent(type, callback) {
    type = type.replace(/^\//, "");
    this.makeRequest(`/api/content/${type}`, null, callback);
  }

  createContact(contact, callback, errorCallback) {
    this.makeRequest("/api/contact", contact, callback, errorCallback);
  }

  login(username, password, callback, errorCallback) {
    this.makeRequest("/api/login", { username, password }, (data) => {
      localStorage.setItem("token", data.token);
      if (callback) {
        callback(data);
      }
    }, (response) => {
      try {
        if (errorCallback) {
          if (response.json) {
            response.json().then((data) => {
              errorCallback(data.message);
            });
          } else {
            errorCallback(response);
          }
        }
      } catch (error) {
        errorCallback(error.message);
      }
    });
  }
  
  fetchContacts(callback) {
    this.makeRequest("/api/contacts", null, callback);
  }

  listUsers(callback) {
    this.makeRequest("/api/users", null, callback);
  }

  saveUser(user, callback, errorCallback) {
    this.makeRequest("/api/user", user, callback, errorCallback);
  }

  deleteUser(id, callback, errorCallback) {
    this.makeRequest(`/api/deleteUser`, { id }, callback, errorCallback);
  }

  listContent(callback) {
    this.makeRequest("/api/listContent", null, callback);
  }

  getContentDetails(id, callback) {
    this.makeRequest(`/api/contentDetails/${id}`, null, callback);
  }

  saveContent(item, callback, errorCallback) {
    this.makeRequest("/api/saveContent", item, callback, errorCallback);
  }

  deleteContent(id, callback, errorCallback) {
    this.makeRequest(`/api/deleteContent`, { id }, callback, errorCallback);
  }
}

export default new Api();
